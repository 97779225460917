import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import { MappedPurchasableVariant } from "@smartrr/shared/utils/useVariantToPurchasableMap";

export const PRODUCT_SELECTION_MAX = 4; // limiting number of products user can display on customer portal

/**
 * true = Has reached max selected quantity
 *
 * false = Has *not* reached max selected quantity
 */
export function hasSelectedIdsReachedMaxQuantity(
  addedItemId: string,
  selectedProductOrVariantIds: string[],
  variantShopifyIdToPurchaseableMap: MappedPurchasableVariant
) {
  // check if user has already selected max num of products

  // if nothing new is provided we can return early
  if (!addedItemId) {
    return false;
  }

  // grabbing only variant shopify ids
  const trimmedSelectedVariantIds = selectedProductOrVariantIds
    .filter(id => id.includes("ProductVariant"))
    .map(id => shopifyGidToNumber(id));

  const selectedProductIds = selectedProductOrVariantIds.filter(id => !id.includes("ProductVariant")) ?? [];

  if (new Set(trimmedSelectedVariantIds).size !== trimmedSelectedVariantIds.length) {
    throw new Error("Duplicate Shopify IDs found in selection");
  }

  // Grabbing the corresponding product for variants that don't already have parent product within array
  const selectedProductShopifyIds = trimmedSelectedVariantIds.reduce((acc, curr) => {
    const product = variantShopifyIdToPurchaseableMap[curr];

    // only selecting unique product ids
    if (!product?.shopifyId || acc.includes(product.shopifyId)) {
      return acc;
    }

    acc.push(product.shopifyId);
    return acc;
  }, selectedProductIds as string[]);

  const isVariantId = !!addedItemId.includes("ProductVariant");

  const addedItemProductId = isVariantId
    ? (variantShopifyIdToPurchaseableMap[shopifyGidToNumber(addedItemId)]?.shopifyId ?? "")
    : addedItemId;

  // checking if the list of selected items is already at the max length without taking into account the newly selected item
  return (
    selectedProductShopifyIds.length >= PRODUCT_SELECTION_MAX &&
    !selectedProductShopifyIds.includes(addedItemProductId)
  );
}
